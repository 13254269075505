@media print {
  body {
    background: none;
    font-size: 1.1em;
  }

  #root > div:first-child > a {
    display: none;
  }

  #wrap {
    margin-top: 0;
    max-width: 100%;
  }

  .noprint {
    display: none;
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
  }

  .row {
    width: inherit;
    padding: 20px 0;
    margin: 0 40px;
    color: black;
  }

  .row {
    display: flex;
  }

  .row .span3 {
    flex: 3;
  }

  .row .span9 {
    flex: 9;
  }

  .rov.cv-section {
    page-break-inside: avoid;
  }

  .xp {
    page-break-after: always;
  }

  .row .cv-item {
    padding-bottom: 10px;
    margin-bottom: 10px;
    page-break-inside: avoid;
  }

  ul.techs li .pill {
    color: black;
  }

  .description p,
  .cv-item p {
    line-height: 1.3em;
  }

  .row.cv-section.projects {
    height: 0;
    margin: 0;
    padding: 0;
  }
}
